
import Vue from 'vue';
import * as VeeValidate from 'vee-validate';
import { extend, ValidationObserver, ValidationProvider, setInteractionMode, classes } from "vee-validate";
import { required, min, max } from "vee-validate/dist/rules";
import VueMask from 'v-mask'
Vue.use(VueMask);

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('setInteractionMode', setInteractionMode);
export default ({ app, store, $axios }) => {
  Vue.use(VeeValidate, {
    classes: true,
    classNames: {
      valid: 'is-valid',
      invalid: 'is-invalid'
    }
  });
  const apiUrl = `${process.env.NUXT_ENV_API_VALET}/api/v2`;
  extend("nameRequired", {
    ...required,
    message: "Full name is required."
  });
  extend("nameMin", {
    ...min,
    message: "First and last name are required."
  });
  extend("nameLookup", {
    message: "Enter your valid first and last name.",
    validate: localValue => {
      let lookup = null;
      let localToken = store.state.localStorage.leadToken;
      let url = `${apiUrl}/validate/name`;
      if (localValue.length > 2) {
        let formData = {
          lead_token: localToken,
          value: localValue
        };
        lookup = app.$axios
        .post(url, formData)
        .then(res => {
          return {
            valid: res.data.valid
          };
        });
      }
      return lookup;
    }
  });
  extend("pickupRequired", {
    ...required,
    message: "Pickup location is required"
  });
  extend("pickupMin", {
    ...min,
    message: "Enter {length} digit zip code."
  });
  extend("pickupMax", {
    ...max,
    message: "Enter {length} digit zip code."
  });
  extend("destinationRequired", {
    ...required,
    message: "Destination location is required."
  });
  extend("destinationMin", {
    ...min,
    message: "Enter {length} digit zip code."
  });
  extend("destinationMax", {
    ...max,
    message: "Enter {length} digit zip code."
  });
  extend("emailRequired", {
    ...required,
    message: "Email address is required."
  });
  extend("emailMin", {
    ...min,
    message: "Email address is required."
  });
  extend("emailLookup", {
    message: "Enter your valid email address.",
    validate: localValue => {
      let lookup = null;
      let localToken = store.state.localStorage.leadToken;
      let url = `${apiUrl}/validate/email`;
      if (localValue.length > 4) {
        let formData = {
          lead_token: localToken,
          value: localValue
        };
        lookup = app.$axios
        .post(url, formData)
        .then(res => {
          return {
            valid: res.data.valid
          };
        });
      }
      return lookup;
    }
  });
  extend("verifyPickup", {
    ...required,
    message: "Select location from drop down."
  });

  extend("sizeRequired", {
    ...required,
    message: "Home size selection is required."
  });
  extend("phoneMin", {
    ...min,
    message: "Min: Phone number must be {length} digits."
  });
  extend("phoneRequired", {
    ...required,
    message: "Phone number is required."
  });
  extend("phoneLookup", {
    message: "Enter your active and valid phone number.",
    validate: localValue => {
      let lookup = null;
      let localToken = store.state.localStorage.leadToken;
      const stripPhone = localValue.replace(/\D/g, "");
      if (stripPhone && stripPhone.length === 10) {
        let url = `${apiUrl}/validate/phone`;
        let formData = {
          lead_token: localToken,
          value: stripPhone
        };
        lookup = app.$axios
          .post(url, formData)
          .then(res => {
            return res.data;
          })
      }
      return stripPhone && stripPhone.length < 10 ? "" : lookup;
    }
  });
  extend("requiredText", {
    ...required,
    message: "Text verification required."
  });
  extend("minText", {
    ...min,
    message: "Verification code should be 4 numbers"
  });
  extend("validateText", {
    message: "Invalid text verification code.",
    validate: localValue => {
      let lookup = null;
      localValue = localValue.replace(/\D/g, "");
      let url = `${apiUrl}/text/validate/${localValue}`;
      let textId = store.state.localStorage.text_id;
      let formData = {
        text_id: textId
      };
      if (localValue.length > 3) {
        lookup = app.$axios
        .post(url, formData)
        .then(res => {
          console.log(res);
          return {
            valid: res.data.valid
          };
        })
      }
      return lookup;
    }
  });
};
