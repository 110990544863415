export const state = () => ({
  keyword: '',
	source: '',
	platform: '',
	referrer: '',
  leadToken: '',
  destination: {
    city: '',
    state: '',
    zipcode: '',
    value: '',
  },
  email: '',
  firstName: '',
  keyword: '',
  lastName: '',
  moveDate: '',
  moveSize: {
    key: '',
    value: '',
    type: '',
  },
  origin: {
    city: '',
    state: '',
    zipcode: '',
    value: '',
  },
  phone: '',
  referrer: '',
  source: '',
  submitlink: 'submit',
  text_code: '',
  text_id: '',
  submitted: '',
  current: '/',
});
export const mutations = {
  setMeta(state, payload){
    state.keyword = payload.keyword;
    state.source = payload.source;
    state.platform = payload.platform;
    state.referrer = payload.referrer;
  },
  setFormToken(state, payload){
    state.leadToken = payload;
  },
  setFormOriginValue(state, payload){
    state.origin.value = payload;
  },
  setFormOrigin(state, payload){
    state.origin.city = payload.city;
    state.origin.state = payload.state;
    state.origin.zipcode = payload.zipcode;
    state.origin.value = payload.value;
  },
  setFormDestinationValue(state, payload){
    state.destination.value = payload;
  },
  setFormDestination(state, payload){
    state.destination.city = payload.city;
    state.destination.state = payload.state;
    state.destination.zipcode = payload.zipcode;
    state.destination.value = payload.value;
  },
  setFormMoveSize(state, payload){
    state.moveSize.value = payload;
  },
  setFormDate(state, payload){
    state.moveDate = payload;
  },
  setFormName(state, payload) {
    state.firstName = payload.firstName;
    state.lastName = payload.lastName;
  },
  setFormEmail(state, payload){
    state.email = payload;
  },
  setFormPhone(state, payload){
    state.phone = payload;
  },
  setResubmit(state, payload){
    state.submitlink = payload;
  },
  setFormTextCode(state, payload){
    state.text_code = payload;
  },
  setFormText(state, payload){
    state.text_id = payload;
  },
  setFormSubmitted(state, payload){
    state.submitlink = payload;
  },
  setCurrent(state, payload){
    state.current = payload;
  },
  setFormReset(state, payload){
    state.leadToken = payload;
    state.origin.city = payload;
    state.origin.state = payload;
    state.origin.zipcode = payload;
    state.origin.value = payload;
    state.destination.city = payload;
    state.destination.state = payload;
    state.destination.zipcode = payload;
    state.destination.value = payload;
    state.moveSize.value = payload;
    state.firstName = payload;
    state.lastName = payload;
    state.email = payload;
    state.phone = payload;
    state.submitlink = 'submit';
    state.text_id = payload;
    state.current = '/';
  },
};
