<template>
  <div v-cloak>
    <top />
    <nuxt />
  </div>
</template>
<script>
export default {
  components: {
    top: () => import("@@/components/top")
  },
  head() {
    return {
      htmlAttrs: {
        class: `${this.$route.name}`
      },
      bodyAttrs: {
        class: `${this.$route.name}`
      }
    };
  },
  mounted() {
    if (this.$store.state.localStorage.current !== $nuxt.$route.path) {
      this.$router.push(this.$store.state.localStorage.current);
    }
    const urlQuery = this.$route;
    const browserData = {
      keyword: urlQuery.query.keyword || "",
      source: urlQuery.query.source || "tm",
      platform: window.navigator.platform || "",
      referrer: document.referrer || window.location.href
    };
    this.$store.commit("localStorage/setMeta", browserData);
    window.addEventListener("beforeinstallprompt", e => {
      e.preventDefault();
    });
  }
};
</script>
