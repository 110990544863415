import Vue from 'vue'
import VueSegmentAnalytics from 'vue-segment-analytics'

export default function (context) {
  const options = {"id":"z59z5mE1fg5BpbXc5rleU8YbqcdvHzpn","useRouter":false}
  const router = options.useRouter && context.app ? context.app.router : undefined
  Vue.use(VueSegmentAnalytics, {
    id: options.id,
    router: router
  })
  Vue.prototype.$analytics = window.analytics
}
