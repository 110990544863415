import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _116b9f98 = () => interopDefault(import('../pages/date.vue' /* webpackChunkName: "pages/date" */))
const _6b3ac9d8 = () => interopDefault(import('../pages/destination.vue' /* webpackChunkName: "pages/destination" */))
const _a83e53bc = () => interopDefault(import('../pages/email.vue' /* webpackChunkName: "pages/email" */))
const _144573f5 = () => interopDefault(import('../pages/name.vue' /* webpackChunkName: "pages/name" */))
const _1faee218 = () => interopDefault(import('../pages/phone.vue' /* webpackChunkName: "pages/phone" */))
const _4de7a32a = () => interopDefault(import('../pages/size.vue' /* webpackChunkName: "pages/size" */))
const _6abe1d97 = () => interopDefault(import('../pages/text.vue' /* webpackChunkName: "pages/text" */))
const _0c0a253e = () => interopDefault(import('../pages/validatedestination.vue' /* webpackChunkName: "pages/validatedestination" */))
const _0b45cb08 = () => interopDefault(import('../pages/validatepickup.vue' /* webpackChunkName: "pages/validatepickup" */))
const _5b9026fb = () => interopDefault(import('../pages/verifydestination.vue' /* webpackChunkName: "pages/verifydestination" */))
const _4962369f = () => interopDefault(import('../pages/verifypickup.vue' /* webpackChunkName: "pages/verifypickup" */))
const _0547af50 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/date",
    component: _116b9f98,
    name: "date"
  }, {
    path: "/destination",
    component: _6b3ac9d8,
    name: "destination"
  }, {
    path: "/email",
    component: _a83e53bc,
    name: "email"
  }, {
    path: "/name",
    component: _144573f5,
    name: "name"
  }, {
    path: "/phone",
    component: _1faee218,
    name: "phone"
  }, {
    path: "/size",
    component: _4de7a32a,
    name: "size"
  }, {
    path: "/text",
    component: _6abe1d97,
    name: "text"
  }, {
    path: "/validatedestination",
    component: _0c0a253e,
    name: "validatedestination"
  }, {
    path: "/validatepickup",
    component: _0b45cb08,
    name: "validatepickup"
  }, {
    path: "/verifydestination",
    component: _5b9026fb,
    name: "verifydestination"
  }, {
    path: "/verifypickup",
    component: _4962369f,
    name: "verifypickup"
  }, {
    path: "/",
    component: _0547af50,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
